import { FC } from 'react';
import { TextCarousel } from '../../components/TextCarousel';
import LOGO from '../../assets/images/auth/Logo.png';
import { 
  AuthLayoutWrapper, 
  AuthLayoutWrapperLeft,
  AuthLayoutWrapperLogo,
  AuthLayoutWrapperChildrenContainer,
  AuthLayoutWrapperRight,
  AuthLayoutWrapperCarouselWrapper,
} from './styles';

interface IProps {
  children: JSX.Element,
}

export const AuthLayout: FC<IProps> = ({ children }): JSX.Element => {
  return (
    <AuthLayoutWrapper>
      <AuthLayoutWrapperLeft>
        <AuthLayoutWrapperLogo src={LOGO}/>
        <AuthLayoutWrapperChildrenContainer>
          {children}
        </AuthLayoutWrapperChildrenContainer>
      </AuthLayoutWrapperLeft>
      <AuthLayoutWrapperRight>
        <AuthLayoutWrapperCarouselWrapper>
          <TextCarousel />
        </AuthLayoutWrapperCarouselWrapper>
      </AuthLayoutWrapperRight>
    </AuthLayoutWrapper>
  )
}