import * as yup from 'yup';

export const passRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
const minUserNameLength = 2;
const maxUserNameLength = 24;
const passMinLength = 8;

export const getPasswordSchema = ({ length = passMinLength, passReg = passRegExp } = {}) =>
  yup
    .string()
    .min(length, `Password must be at least ${length} characters`)
    .required('Password is required!')
    .matches(/^\S*$/, 'Whitespace is not allowed')
    .matches(
      passReg,
      `Must Contain at least ${length} Characters, One Uppercase, One Lowercase, One Number`,
    );

export const registerSchema = yup.object().shape({
  username: yup
    .string()
    .min(minUserNameLength, `First name must be at least ${minUserNameLength} characters`)
    .max(maxUserNameLength, `First name must not exceed ${maxUserNameLength} characters`)
    .trim('The contact name cannot include leading and trailing spaces')
    .required('User Name is required!'),
  userSurname: yup
    .string()
    .min(minUserNameLength, `Last name must be at least ${minUserNameLength} characters`)
    .max(maxUserNameLength, `Last name must not exceed ${maxUserNameLength} characters`)
    .trim('The contact name cannot include leading and trailing spaces')
    .required('User Surname is required!'),
  email: yup.string().email('Please enter correct email address').required('Email is required!'),
  password: getPasswordSchema(),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match').required('Confirm password is required!'),
  acceptTerms: yup.bool().oneOf([true], 'Accept Terms is required'),
});

export const loginSchema = yup.object().shape({
  email: yup.string().email('Please enter correct email address').required('Email is required!'),
  password: getPasswordSchema(),
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email('Please enter correct email address').required('Email is required!'),
});

export const forgotPasswordConfirmationSchema = yup.object().shape({
  newPassword: getPasswordSchema(),
  confirmNewPassword: getPasswordSchema()
    .oneOf([yup.ref('newPassword')], 'Passwords do not match').required(),
});
