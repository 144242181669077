import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Text = styled.p`
  color: var(--primary-grey-2);
  margin-top: 50px;
  max-width: 500px;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
`