import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const TextCondition = styled.span`
  color: var(--primary-green-5);
  font-weight: 600;
  font-size: 14px;
  margin: 20px 0 15px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  width: 150px;
  justify-content: space-between;
  align-items: center;
`

export const LinkButton = styled.button`
  border: none;
  background: none;
`

export const LineDiv = styled.div`
  width: 1px;
  height: 30px;
  background: var(--primary-grey-1);
`

export const Logo = styled.img``