import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const SlideHolder = styled.div`
  width: 100%;
  height: 100vh;
`

export const imageWrapper = styled.div`
  height: 65%;
  position: relative;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`

export const ImageWrapperShadow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(255,255,255,0.95) 5%, rgba(255, 255, 255, 0) 40%);
  top: 0;
`

export const SlideTitle = styled.h1`
  color: var(--primary-green-6);
  font-weight: 600;
  font-size: 25px;
  margin: 20px 0;
`

export const SlideText = styled.p`
  margin: 0 auto 50px;
  width: 80%;
  max-width: 400px;
  text-align: center;
  color: var(--primary-green-5)
`

export const Indicator = styled.li<{
  isSelected?: boolean
}>`
  width: 8px;
  height: 8px;
  display: inline-block;
  margin: 0 8px;
  position: relative;
  border-radius: 50%;
  ${props => props && css`
    background: ${props.isSelected ? 'var(--primary-green-5)' : 'var(--primary-grey-1)'};
  `}
`