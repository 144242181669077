import styled from "styled-components";

export const AuthLayoutWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`

export const AuthLayoutWrapperLeft = styled.div`
  width: 100%;
  padding: min(100px, 5%);
  background: var(--primary-green-1);
`

export const AuthLayoutWrapperLogo = styled.img`
  display: block;
  margin: 0 auto;
`
export const AuthLayoutWrapperChildrenContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AuthLayoutWrapperRight = styled.div`
  width: 45%;
  min-width: 350px;
  max-width: 600px;
`

export const AuthLayoutWrapperCarouselWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`