import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { privateRoutes, publicRoutes, RouteNames } from '../../router';

const AppRouter = () => {
  // const authToken = useSelector(authTokenSelector);
  const authToken = false;

  return authToken ? (
    <Routes>
      {privateRoutes.map(route => (
        <Route path={route.path} element={<route.element />} key={route.path} />
      ))}
      {/* <Route path={RouteNames.ANY} element={<Navigate to={RouteNames.DATASETTINGS} replace />} /> */}
    </Routes>
  ) : (
    <Routes>
      {publicRoutes.map(route => (
        <Route path={route.path} element={<route.element />} key={route.path} />
      ))}
      <Route path={RouteNames.ANY} element={<Navigate to={RouteNames.LOGIN} replace />} />
    </Routes>
  );
};

export default AppRouter;
