import { FC, useState } from 'react';
import { useForm, UseFormRegister } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as schemas from '../../config/yupSchema';
import { Button } from '../Button';
import SHOW_ICON from '../../assets/icons/showPassword.png'
import HIDE_ICON from '../../assets/icons/hidePassword.png'
import { Navigator } from '../../services/navigatorService';
import { RouteNames } from '../../router';
import * as S from './style';
import { ErrorAlert } from '../ErrorAlert';

interface IAuthForm {
  type: 'sign-in' | 'sign-up' | 'reset-password';
  formTitle: string;
  submit: () => void;
}

export interface RegisterFormValues {
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  userSurname: string;
}

const Input = ({
  title,
  placeholder,
  schema,
  type,
  errorFields,
  register,
  updateCurrentField,
  changeableType
}: {
  title: string,
  placeholder: string,
  schema: "username" | "email" | "password" | "confirmPassword" | "userSurname",
  type?: string,
  errorFields: string[] | [],
  register: UseFormRegister<RegisterFormValues>,
  updateCurrentField: (currentField: string) => void,
  changeableType?: boolean
}) => {

  const [ inputType, setInputType ] = useState<'password' | 'text'>('password')

  const isInvalid = errorFields.some(field => field === schema);

  const onInputFocus = () => {
    updateCurrentField(schema)
  }

  const onChangeInputType = () => {
    if (inputType === 'password') {
      setInputType('text')
    } else if (inputType === 'text') {
      setInputType('password')
    }
  }

  return (
    <S.InputLabel isInvalid={isInvalid}>
      <S.InputTitle>{title}</S.InputTitle>
      <S.Input 
        type={changeableType ? inputType : type} 
        placeholder={placeholder} 
        id={schema} 
        onFocus={onInputFocus}
        {...register(schema)}
      />
      {
        changeableType && 
        <S.ShowHidePassButton onClick={onChangeInputType}>
          <img src={inputType === 'password' ? SHOW_ICON : HIDE_ICON}/>
        </S.ShowHidePassButton>
      }
    </S.InputLabel>
  )
}

export const AuthForm: FC<IAuthForm> = ({
  type,
  formTitle,
  submit
}): JSX.Element | null => {

  const {
    register,
    control,
    handleSubmit,
    formState,
  } = useForm<RegisterFormValues>({
    resolver: yupResolver(
      type === 'sign-up' ? 
      schemas.registerSchema :
      type === 'sign-in' ? 
      schemas.loginSchema : 
      schemas.forgotPasswordSchema
    ),
  });

  const [ currentField, setCurrentField ] = useState<string>('')

  const formSubmitHandler = () => {
    submit();
  }

  const updateCurrentField = (fieldName: string) => {
    setCurrentField(fieldName);
  }


  const redirectToSignInPage = () => Navigator.push(RouteNames.LOGIN)
  const redirectToSignUpPage = () => Navigator.push(RouteNames.REGISTER)
  const redirectToResetPasswordPage = () => Navigator.push(RouteNames.RESET_PASSWORD)
    
  const errorMessage = formState.errors[currentField]

  switch (type) {
    case 'sign-in':
      return (
        <S.Form>
          <S.FormLabel>{formTitle}</S.FormLabel>
          <Input 
            errorFields={Object.keys(formState.errors)} 
            register={register} 
            title='E-mail address' 
            placeholder='' 
            schema='email'
            updateCurrentField={updateCurrentField}
          />
          <Input 
            errorFields={Object.keys(formState.errors)} 
            register={register} 
            type='password' 
            title='Password' 
            placeholder='' 
            schema='password'
            updateCurrentField={updateCurrentField}
            changeableType
          />
          {errorMessage && <ErrorAlert text={errorMessage.message} top='250px' width='100%'/>}
          <Button 
            marginTop={0} 
            title='I don’t remember my password' 
            variant='secondary' 
            width='250px'
            fullWidth
            align='left'
            onClick={redirectToResetPasswordPage}
          />
          <Button 
            marginTop='70px' 
            title='Sign in' 
            variant='primary' 
            width='250px' 
            onClick={handleSubmit(formSubmitHandler)}
          />
          <Button 
            marginTop={0} 
            title='Create account' 
            variant='secondary' 
            width='250px' 
            onClick={redirectToSignUpPage}
          />
        </S.Form>
      )
    case 'sign-up':
      return (
        <S.Form>
          <S.FormLabel>{formTitle}</S.FormLabel>
          <Input 
            errorFields={Object.keys(formState.errors)} 
            register={register} 
            title='E-mail address' 
            placeholder='' 
            schema='email'
            updateCurrentField={updateCurrentField}
          />
          <Input 
            errorFields={Object.keys(formState.errors)} 
            register={register} 
            title='First name' 
            placeholder='' 
            schema='username'
            updateCurrentField={updateCurrentField}
          />
          <Input 
            errorFields={Object.keys(formState.errors)} 
            register={register} 
            title='Last name' 
            placeholder='' 
            schema='userSurname'
            updateCurrentField={updateCurrentField}
          />
          <Input 
            errorFields={Object.keys(formState.errors)} 
            register={register} 
            type='password' 
            title='Password' 
            placeholder='' 
            schema='password'
            updateCurrentField={updateCurrentField}
            changeableType
          />
          <Input 
            errorFields={Object.keys(formState.errors)} 
            register={register} 
            type='password' 
            title='Confirm password' 
            placeholder='' 
            schema='confirmPassword'
            updateCurrentField={updateCurrentField}
            changeableType
          />
          {errorMessage && <ErrorAlert text={errorMessage.message} top='430px' width='100%'/>}
          <Button 
            marginTop='70px'
            title='Create account' 
            variant='primary' 
            width='250px' 
            onClick={handleSubmit(formSubmitHandler)}
          />
          <Button 
            marginTop={0} 
            title='Have account?' 
            variant='secondary' 
            width='250px'
            onClick={redirectToSignInPage}
          />
        </S.Form>
      )
      case 'reset-password':
        return (
          <S.Form>
            <S.FormLabel>{formTitle}</S.FormLabel>
            <Input 
              errorFields={Object.keys(formState.errors)} 
              register={register} 
              title='E-mail address' 
              placeholder='' 
              schema='email'
              updateCurrentField={updateCurrentField}
            />
            {errorMessage && <ErrorAlert text={errorMessage.message} top='140px' width='100%'/>}
            <Button 
              marginTop='50px'
              title='Reset password' 
              variant='primary' 
              width='250px'
              onClick={handleSubmit(formSubmitHandler)}
            />
          </S.Form>
        )
    default:
      return <></>
  }
}