import { AuthLayout } from "../../layouts/AuthLayout";
import { AuthForm } from "../../components/AuthForm";
import GOOGLE_ICON from '../../assets/icons/google-icon.png';
import FACEBOOK_ICON from '../../assets/icons/facebook-icon.png';
import { 
  FormContainer,
  TextCondition, 
  ButtonsContainer,
  LinkButton,
  LineDiv,
  Logo
} from "./styles";

export const SignInPage = () => {
  return (
    <AuthLayout>
      <FormContainer>
        <AuthForm formTitle="Sign in" type="sign-in" submit={() => alert('Success')} />
        <TextCondition>or use</TextCondition>
        <ButtonsContainer>
          <LinkButton >
            <Logo src={FACEBOOK_ICON}/>
          </LinkButton>
          <LineDiv/>
          <LinkButton>
            <Logo src={GOOGLE_ICON}/>
          </LinkButton>
        </ButtonsContainer>
      </FormContainer>
    </AuthLayout>
  )
}

