import { FC } from "react";
import { Navigator } from "../../services/navigatorService";
import { Link } from './style';

interface IProps {
  route: string,
  title: string,
  color?: string,
  fontWeight?: number,
  fontSize?: number,
  fontUnity?: string
}

export const InternalLink: FC<IProps> = ({
  route, 
  title, 
  color,
  fontWeight,
  fontSize,
  fontUnity
}) => {
  const onClickHandler = () => {
    Navigator.push(route)
  }
  return (
    <Link 
      fontWeight={fontWeight}
      fontSize={fontSize}
      fontUnity={fontUnity}
      color={color} 
      onClick={onClickHandler}
    >
      {title}
    </Link>
  )

}