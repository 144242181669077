import styled, { css } from "styled-components";

export const InputLabel = styled.label<{
  isInvalid: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5px 30px;
  margin: 5px auto;
  border-radius: 20px;
  width: 100%;
  background: var(--white);
  ${props => props && css`
    transition: ${props.isInvalid && 'all 0.3s'};
    box-shadow: ${props.isInvalid && 'inset 0px 0px 1px 1px var(--red)'};
  `}
`

export const InputTitle = styled.span`
  color: var(--primary-grey-1);
  font-size: 14px;
  font-weight: 500;
`

export const Input = styled.input`
  border: none;
  background: none;
  outline: none;
  font-weight: 600;
  margin: 10px 0 5px 0;
  color: var(--primary-green-5);
  font-size: 16px;
`

export const ShowHidePassButton = styled.div`
  position: absolute;
  top: 50%;
  right: 30px;
  border: none;
  background: none;
  cursor: pointer;
`


export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-width: 350px;
  max-width: 400px;
  position: relative;
`
export const FormLabel = styled.label`
  font-weight: 600;
  font-size: 26px;
  line-height: 26px;
  margin: 0 0 40px;
`