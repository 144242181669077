import React from 'react';
import { SignInPage } from '../pages/SignIn';
import { SignUpPage } from '../pages/SignUp';
import { ResetPassword } from '../pages/ResetPassword';

export interface IRoute {
  path: string;
  element: React.ComponentType;
}

export enum RouteNames {
  // LOGIN = '/', // temporary
  LOGIN = '/login',
  REGISTER = '/register',
  RESET_PASSWORD = '/reset-password',
  ANY = '*',
}

export const publicRoutes: IRoute[] = [
  {
    path: RouteNames.REGISTER,
    element: SignUpPage,
  },
  {
    path: RouteNames.LOGIN,
    element: SignInPage,
  },
  {
    path: RouteNames.RESET_PASSWORD,
    element: ResetPassword,
  },
];

export const privateRoutes: IRoute[] = [
  // {
  //   path: RouteNames.DASHBOARD,
  //   element: DashboardPage,
  // },
  // {
  //   path: RouteNames.DATASETTINGS,
  //   element: DataSettingsPage,
  // },
  // {
  //   path: RouteNames.SUCCESS_CONFIRM_EMAIL,
  //   element: SuccessConfirmPage,
  // },
  // {
  //   path: RouteNames.RISKBOARD,
  //   element: RiskBoardPage,
  // },
];