import { AuthLayout } from "../../layouts/AuthLayout";
import { AuthForm } from "../../components/AuthForm";
import { FormContainer, Text } from "./styles";
import { InternalLink } from "../../components/InternalLink";


export const SignUpPage = () => {
  return (
    <AuthLayout>
      <FormContainer>
        <AuthForm formTitle="Sign up" type="sign-up" submit={() => alert('Success')} />
        <Text>By registering you agree to our <InternalLink title="Terms" fontSize={14} fontWeight={800} fontUnity="px" color="var(--primary-green-3)" route="https://en.wikipedia.org/wiki/Term"/> and <InternalLink fontWeight={800} fontSize={14} fontUnity="px" color="var(--primary-green-3)" title="Privacy Policy" route="https://en.wikipedia.org/wiki/Privacy_policy"/> and confirm
that you are of <InternalLink fontSize={14} fontUnity="px" title="legal age" fontWeight={800} color="var(--primary-green-3)" route="https://en.wikipedia.org/wiki/Legal_age"/> to participate our App.</Text>
      </FormContainer>
    </AuthLayout>
  )
}

