import { FC, CSSProperties, MouseEvent, KeyboardEvent, ReactNode, Key, KeyboardEventHandler, MouseEventHandler } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import TEST from '../../assets/images/auth/background.png';
import * as S from './style';

export const TextCarousel: FC = (): JSX.Element => {

  interface ISlide {
    id: string | number, 
    title: string, 
    text: string,
    src: string,
  }

  const config = {
    showThumbs: false,
    showStatus: false,
    infiniteLoop: true,
    autoPlay: true,
    transitionTime: 1000,
    interval: 3000,
    showArrows: false,
    renderIndicator: (
      onClickHandler: MouseEventHandler<HTMLLIElement> | KeyboardEventHandler<HTMLLIElement> | undefined, 
      isSelected: boolean, 
      index: number, 
      label: string
      ) => {
      if (isSelected) {
          return (
              <S.Indicator
                  isSelected={isSelected}
                  aria-label={`Selected: ${label} ${index + 1}`}
                  title={`Selected: ${label} ${index + 1}`}
              />
          );
      }
      return (
          <S.Indicator
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              title={`${label} ${index + 1}`}
              aria-label={`${label} ${index + 1}`}
          />
      );
    }
  }

  const slides: ISlide[] = [
    {
      id: 1,
      title: 'Beautiful design',
      text: 'Plant is a smart indoor farm that allows you to grow your favorite herbs all year round',
      src: TEST,
    },
    {
      id: 2,
      title: 'Beautiful design',
      text: 'Plant is a smart indoor farm that allows you to grow your favorite herbs all year round',
      src: TEST,
    },
    {
      id: 3,
      title: 'Beautiful design',
      text: 'Plant is a smart indoor farm that allows you to grow your favorite herbs all year round',
      src: TEST,
    }
  ]

  return (
    <S.Wrapper>
      <Carousel {...config}>
        {slides.map((slide: ISlide ) => 
          <S.SlideHolder key={slide.id}>
            <S.imageWrapper>
              <S.Image src={slide.src} alt="img"/>
              <S.ImageWrapperShadow/>
            </S.imageWrapper>
            <S.SlideTitle>{slide.title}</S.SlideTitle>
            <S.SlideText>{slide.text}</S.SlideText>
          </S.SlideHolder>
        )}
      </Carousel>
    </S.Wrapper>
  )
}