import styled, { css } from "styled-components";

export const Link = styled.button<{
  fontWeight?: number,
  fontSize?: number,
  fontUnity?: string
}>`
  background: none;
  border: none;

  ${props => props && css`
    color: ${props.color};
    font-weight: ${props.fontWeight};
    font-size: ${props.fontSize}${props.fontUnity};
  `}
`