import { StyledButton } from './styles';

export const Button = ({
  title,
  variant,
  onClick,
  width,
  marginTop,
  marginBottom,
  fullWidth,
  align,
}: {
  title: string,
  variant: 'primary' | 'secondary',
  onClick: () => void,
  width: string,
  marginTop?: string | 0,
  marginBottom?: string | 0,
  fullWidth?: boolean,
  align?: 'left' | 'right'
}) => {
  return (
    <StyledButton
      type='button'
      variant={variant}
      width={width}
      marginTop={marginTop}
      marginBottom={marginBottom}
      fullWidth={fullWidth}
      align={align}
      onClick={onClick}
    >
      {title}
    </StyledButton>
  )
}