import styled, { css } from "styled-components";

export const StyledButton = styled.button<{
  variant: 'primary' | 'secondary';
  width?: string;
  marginTop?: string | 0;
  marginBottom?: string | 0;
  fullWidth?: boolean;
  align?: 'left' | 'right';
}>`
  border: none;
  padding: 15px;
  border-radius: 25px;
  font-weight: 500;
  line-height: 16px;
  font-size: 16px;
  margin: 0 auto;
  ${(props => props && css`
    border: none;
    padding: 15px;
    border-radius: 25px;
    font-weight: 500;
    line-height: 16px;
    font-size: 16px;
    margin: 0 auto;
    width: ${!props.fullWidth ? props.width : '100%'};
    margin-top: ${props.marginTop};
    margin-bottom: ${props.marginBottom};
    text-align: ${props.align ? props.align : 'center'};
    ${props.variant === 'primary' && `
      color: var(--white);
      background: var(--primary-green-5);
    `}
    ${props.variant === 'secondary' && `
      color: var(--primary-green-5);
      font-weight: 600;
      font-size: 14px;
      background: none;
    `}
  `)}
`