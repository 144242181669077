import  { FC } from "react";
import { StyledErrorWrapper } from './style';

interface IProps {
  text?: string,
  top?: string,
  bottom?: string,
  right?: string,
  width?: string,
}

export const ErrorAlert: FC<IProps> = ({ 
  text,
  top,
  bottom,
  right,
  width,
}) => {
  return (
    <StyledErrorWrapper 
      top={top}
      bottom={bottom}
      right={right}
      width={width}
    >
      {text}
    </StyledErrorWrapper>
  )
}