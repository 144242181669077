import { AuthLayout } from "../../layouts/AuthLayout";
import { AuthForm } from "../../components/AuthForm";
import { FormContainer } from './styles';

export const ResetPassword = () => {
  return (
    <AuthLayout>
      <FormContainer>
        <AuthForm formTitle="Reset password" type="reset-password" submit={() => alert('Success')} />
      </FormContainer>
    </AuthLayout>
  )
}

